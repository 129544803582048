/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-05-06 12:06:46
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-09 23:26:41
 * @FilePath: \wasai-nft-web\src\components\pagehtml\Counter.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";

import '../../assets/images/favicon.png';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/all.min.css';
import '../../assets/css/lightcase.css';
import '../../assets/css/swiper-bundle.min.css';
import '../../assets/css/style.css';
import { useTranslation } from '../../utils/contexts/Localization'
import axios from "axios";

import { Constants, ImgUrl, IsTest } from "../../utils/Config";


function Counter() {
  const { t } = useTranslation()
  const [datass, setData] = useState(0);
  const [datass2, setData2] = useState(0);
  useEffect(() => { 
      axios.get(ImgUrl + "/query/nftinfo")
        .then((response) => {
          // 处理成功响应
           console.log("response.data.data",response.data.data);
 
          setData(response.data.data.total);
          setData2(response.data.data.count);
        }) 
  }, []);   

  return (
    <section className="counter counter--uplifted">
      <div className="container">
        <div className="counter__wrapper">
          <div className="row g-1">
            <div className="col-lg-3 col-sm-6">
              <div className="counter__item">
                <div className="counter__item-content">
                  <h2><span className="purecounter" data-purecounter-start="0" data-purecounter-end="8888">100000</span></h2>
                  <p>{t('Total items')}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="counter__item">
                <div className="counter__item-content">
                  <h2><span className="purecounter" data-purecounter-start="0" data-purecounter-end="1460" data-purecounter-once="false">{datass}</span></h2>
                  <p>{t('Nft Items')}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="counter__item">
                <div className="counter__item-content">
                  <h2><span className="purecounter" data-purecounter-start="0.1" data-purecounter-end="84" data-purecounter-once="false">566</span></h2>
                  <p>{t('floor price')} [USDT]</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="counter__item">
                <div className="counter__item-content">
                  <h2><span className="purecounter" data-purecounter-start="0" data-purecounter-end="13" data-purecounter-once="false">{datass2}</span></h2>
                  <p>{t('Volume Traded')} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Counter;
